import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import { Migration } from "../common/components/migration/migration";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { Book } from "../components/book/book";

export const BookEntrypoint: FC<ILocalizedPage> = _props => {
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {useLocalized("book_title")}
      </Heading>
      <FancyLine />
      <Book />
    </>
  );
};
