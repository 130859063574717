import * as React from "react";
import { FC } from "react";
import {
  Heading,
  HEADING_CLASS
} from "../../common/components/typography/heading";
import { useLocalized } from "../../common/localization/localize";
import classes from "./book.module.css";
import { Link } from "gatsby";

export const Book: FC = () => (
  <div className={classes.container}>
    <div className={classes.text}>
      <Heading level={2} theme={HEADING_CLASS.TEXT}>
        {useLocalized("text_title")}
      </Heading>
      <p>{useLocalized("text_description_1")}</p>
      <p>
        <em>
          {useLocalized("text_description_2")}
          <Link to={useLocalized("text_link_url")}>
            {useLocalized("text_link")}
          </Link>
          {useLocalized("text_description_3")}
        </em>
      </p>
    </div>
    <div className={classes.image}>
      <img src={"/book/buch.png"} alt={useLocalized("image_alt_text")} />
    </div>
  </div>
);
